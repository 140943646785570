html, body {
    background: #fefefe;
    padding: 0px;
    margin: 0px;
  }
  
  * { box-sizing: border-box; }
  
  h1, p { 
   margin-bottom: 0px; 
   margin-top: 0px;
  }
  
  .header {
   background: #000000;
   color: white;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   height: 50px;
   z-index: 99;
  }
  
  .header h1 {
   font-family: 'Helvetica';
   font-weight: 200;
  }
  
  .instructions {
   margin-top: 50px;
   padding: 15px;
   font-size: 14px;
   font-family: 'Helvetica';
  }
  
  .knock-knock-joke-container {
   margin-top: 15px;
   line-height: 20px;
   font-family: 'Helvetica'; 
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2.5vw;
    background: #fefefe;
    justify-content: center;
    padding: 5px;
  }
  
  .card {
    display: flex;
    align-items: center;
    height: 250px;
    flex: 0 0 95vw;
    justify-content: center;
    font-family: 'Helvetica';
    font-weight: 100;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
    border-radius: 0px 0px 7px 0px;
  }
  
  .card-face {
    position: absolute; 
    width: 95vw;
    will-change: transform, opacity;
  }
  
  .front > .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 250px;
  }
  
  .card-front-words-container {
    background: #000000;
    border-radius: 0px 0px 7px 0px;
    flex: .175;
    width: 100%;
    padding: 5px 0px 0px 10px;
  }
  
  .card-front-picture-container {
    flex: .825;
    display: flex;
    align-items: center;
    border: 2px solid black;
    border-radius: 7px 0px 0px 0px;
    border-bottom: 0px;
    justify-content: center;
    width: 100%;
    overflow: hidden;
   }
   
  .card-front-picture-container img { 
  max-width: 150px;
   margin: 0 auto;
  }
  
  .front > .card-content > .card-front-words-container > .card-symbol {
   font-size: 18px;
  }
  
  .front > .card-content > .card-front-words-container > .card-date {
   font-size: 14px;
  }
  
  .back > .card-content { 
    display: flex;
    flex-direction: column;
    height: 250px;
    background: midnightblue; 
    border-radius: 0px 7px 0px 7px;
  }
  
  .card-back-words-container {
   flex: .825;
    padding: 5px 10px 0px 10px;
  }
  
  .card-back-footer-container {
   flex: .175;
   background: white;
   color: midnightblue;
    border: 2px solid midnightblue;
   border-radius: 0px 0px 0px 7px;
    padding: 5px 0px 0px 10px;
    width: 100%;
  }
  
  .back > .card-content > .card-back-footer-container > .card-symbol {
   font-size: 18px;
  }
    
  .back > .card-content > .card-back-words-container > .card-description {
   font-size: 11px;
   text-transform: none;
  }
  
   .marquee {
                  height: 50px;
                  overflow: hidden;
                  position: relative;
                  white-space: nowrap;
              }
              
              .marquee p {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  line-height: 50px;
                  text-align: center;
                  -moz-transform: translateX(100%);
                  -webkit-transform: translateX(100%);
                  transform: translateX(100%);
                  -moz-animation: scroll-left 2s linear infinite;
                  -webkit-animation: scroll-left 2s linear infinite;
                  animation: scroll-left 20s linear infinite;
              }
              
              @-moz-keyframes scroll-left {
                  0% {
                      -moz-transform: translateX(100%);
                  }
                  100% {
                      -moz-transform: translateX(-100%);
                  }
              }
              
              @-webkit-keyframes scroll-left {
                  0% {
                      -webkit-transform: translateX(100%);
                  }
                  100% {
                      -webkit-transform: translateX(-100%);
                  }
              }
              
              @keyframes scroll-left {
                  0% {
                      -moz-transform: translateX(100%);
                      -webkit-transform: translateX(100%);
                      transform: translateX(100%);
                  }
                  100% {
                      -moz-transform: translateX(-100%);
                      -webkit-transform: translateX(-100%);
                      transform: translateX(-100%);
                  }
              }